<template>
  <div class="view">
    <div class="content">
      <logo-banner/>
      <h1>Registrieren</h1>
      <div class="wrapper">
        <label class="editLabel">
          Vorname*:
          <input type="text" required v-model="user.firstname">
        </label>
        <label class="editLabel">
          Nachname*:
          <input type="text" required v-model="user.lastname">
        </label>
        <label class="editLabel">
          Telefonnummer*:
          <input type="tel" required v-model="user.tel">
        </label>
        <label class="editLabel">
          Geburtsdatum*:
          <input type="date" required v-model="user.dateOfBirth">
        </label>

        <span style="color: red; margin-top: 16px">
        *Pflichtfeld
        </span>
        <br>
        <button class="saveButton btn" @click="handleRegistration">
          <h3>
            Registrieren
          </h3>
        </button>
      </div>
    </div>
    <b-modal id="fillFields" title="Registrierung" hide-footer>
      <p class="my-4">Bitte füllen sie alle Felder aus!</p>
      <b-button class="mt-3" variant="success" block @click="$bvModal.hide('fillFields')">OK</b-button>
    </b-modal>
  </div>
</template>

<script>

import LogoBanner from "@/components/LogoBanner";
import {mapGetters} from "vuex";
export default {
  name: "Register",
  components: {LogoBanner},
  methods: {
    handleRegistration () {
      if (this.user.tel !== undefined && this.user.firstname !== undefined && this.user.lastname !== undefined && this.user.dateOfBirth !== undefined) {
        this.$router.push('index')
      } else {
        this.$bvModal.show("fillFields")
      }
    }
  },
  computed: {
    ...mapGetters([
        'getUser'
    ]),
    user() {
      return this.getUser
    }
  }
}
</script>

<style scoped>
.content {
  height: 100%;
  max-height: 100%;
}

h1 {
  text-align: center;
  font-weight: bold;
}

.editLabel {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.editLabel input {
  background: #E5E5E5;
  border: 0;
  border-radius: 5px;
  outline: 0;
  height: 40px;
  padding: 4px;
}

.saveButton {
  margin-top: 24px;
  padding: 8px;
  width: 100%;
  background: #21BD64;
  color: white;
  border-radius: 8px;
}
</style>
